import {
  Button,
  Checkbox,
  Form,
  Input,
  Row,
  Col,
  DatePicker,
  Select,
  Spin,
  Collapse,
  Modal,
} from "antd";
import axios from "axios";
import React, { useState, useRef, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import conf from "../config";
import { ToastContainer, toast } from "react-toastify";
import Cookies from "js-cookie";
import { useMyContext } from "../context/Usecontext";
import { useCallbackPrompt } from "../hooks/useCallbackPrompt";

const Formpage = ({ datafedit, setEditdata, setIslogin }) => {
  const formRef = useRef(null);
  const selectref = useRef(null);
  const [getdatafedit, setGetdatafedit] = useState(null);
  const [edit, setEdit] = useState(false);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { empidforedit, setEmpidforedit } = useMyContext();
  const [promotedata, setPromoteData] = useState([]);
  const [initialValues, setInitialValues] = useState({});
  const [disableyears, setDisableyears] = useState([]);
  const [year, setYear] = useState("");

  const { Panel } = Collapse;

  const [showDialog, setShowDialog] = useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(showDialog);

  const getdetails = async () => {
    try {
      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };

      const response = await axios.get(`${conf.baseurl}/getdetails`, config);
      // console.log(response.data);
      if (response.data == "F") {
        console.log("no data availabele");
      } else {
        const data = datafedit
          ? response.data // If datafedit is not null, skip the filter
          : response.data.filter(
              (item) => item.isAdmin !== 1 && item.isArchive !== true
            );
        // const data = response.data.filter(
        //   (item) => item.isAdmin !== 1 && item.isArchive !== true
        // );

        setData(data);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page
        toast.error("Session expired, Please login again");
        Cookies.remove("isLoggedIn");
        setIslogin(false);
        navigate("/");
      } else {
        // Handle other errors
        console.error("Error:", error.message);
      }
    }
  };

  const defineInitialData = () => {
    const promoteDistributions = {};
    const fundAllocations = {};
    const eppPromoteDistributions = {};
    const eppPromoteAllocations = {};
    if (promotedata.length > 0) {
      promotedata.forEach((item) => {
        promoteDistributions[item._id] = null; // Populate funds for Promote_Distributions
        fundAllocations[item._id] = null; // Populate funds for Fund Allocations
        eppPromoteDistributions[item._id] = null; // Populate funds for EPP Promote Distributions
        eppPromoteAllocations[item._id] = null;
      });
    }

    return {
      Title: null,
      Department: null,
      Manager: null,
      Salary_Bonus: {
        salary: null,
        lowbonusestimate: null,
        highbonusestimate: null,
        bonusestimate: null,
        bonusTarget: null,
        actualBonus: null,
        actualBonusReserve: null,
      },
      extrabonus: null,
      Footnote: null,
      Match: null,
      Insurance: {
        medical: null,
        lifeinsurance: null,
        Dental: null,
        vision: null,
      },
      Promote_Distributions: promoteDistributions,
      Fund_allocations: fundAllocations,
      Other_Earnings: {
        field1: null,
        fieldvalue1: null,
        field2: null,
        fieldvalue2: null,
        field3: null,
        fieldvalue3: null,
      },
      EPP_Promote_Distributions: eppPromoteDistributions,
      EPP_Promote_Distributions2: {
        BVREP_IV_EPD2: null,
        BVREP_V_EPD2: null,
        BVREP_VI_EPD2: null,
        SHF_III_EPD2: null,
        BVF_I_EPD2: null,
        UCC_EPD2: null,
        Cal_Regents_Boston_EPD2: null,
        Cal_Regents_Syracuse_EPD2: null,
        Cal_Regents_Seattle_EPD2: null,
        Harel_EPD2: null,
        Gainesville_EPD2: null,
        OP_Trust_EPD2: null,
        Samsung_Syracuse_EPD2: null,
        Samsung_Austin_EPD2: null,
        Stepstone_EPD2: null,
        BVSHSSF_EPD2: null,
      },
      EPP_Promote_Allocations: eppPromoteAllocations,
    };
  };

  const getpromotedata = async () => {
    try {
      setLoading(true);
      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };
      const response = await axios.get(
        `${conf.baseurl}/getpromotedata`,
        config
      );
      if (response.data.status == "F") {
        console.log("no data available");
      } else {
        setPromoteData(response.data.data);
        setLoading(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Session expired, Please login again");
        setLoading(false);
        Cookies.remove("isLoggedIn");
        setIslogin(false);
        navigate("/");
      } else {
        setLoading(false);
        console.log("Error fetching data:", error.message);
      }
    }
  };

  const getdisableyearsdata = async () => {
    try {
      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };
      const response = await axios.get(
        `${conf.baseurl}/getdisableyears`,
        config
      );

      if (response.status === 200) {
        setDisableyears(response.data.data[0].years);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Session expired, Please login again");

        Cookies.remove("isLoggedIn");
        setIslogin(false);
        navigate("/");
      } else {
        console.log("Error fetching data:", error.message);
      }
    }
  };

  const initializeData = async () => {
    await getdisableyearsdata();
    await getpromotedata();
    await getdetails();
  };

  useEffect(() => {
    const initialData = defineInitialData();
    setInitialValues(initialData);

    if (datafedit === null) {
      if (formRef.current) {
        formRef.current.setFieldsValue(initialData);
      }
    }
  }, [promotedata]);

  useEffect(() => {
    initializeData();
    if (datafedit != null) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      setGetdatafedit(datafedit);
      setEdit("true");

      // console.log("id", datafedit.id);
      setEmpidforedit(datafedit.id);

      const firstDataItem = datafedit;
      setYear(firstDataItem.Year);
      // console.log(firstDataItem);
      formRef.current.setFieldsValue({
        Year: firstDataItem.Year ? dayjs(firstDataItem.Year) : "",
        info: firstDataItem.personid,
        Department: firstDataItem.Department,
        Title: firstDataItem.Title,
        Manager: firstDataItem.Manager,
        Salary_Bonus: {
          salary: firstDataItem?.Salary_Bonus?.salary,
          lowbonusestimate: firstDataItem?.Salary_Bonus?.lowbonusestimate,
          bonusestimate: firstDataItem?.Salary_Bonus?.bonusestimate,
          actualBonus: firstDataItem?.Salary_Bonus?.actualBonus,
          bonusTarget: firstDataItem?.Salary_Bonus?.bonusTarget,
          actualBonusReserve: firstDataItem?.Salary_Bonus?.actualBonusReserve,
          highbonusestimate: firstDataItem.Salary_Bonus?.highbonusestimate,
        },
        extrabonus: firstDataItem?.extrabonus,
        Match: firstDataItem?.Match,
        Footnote: firstDataItem.Footnote,

        Insurance: {
          medical: firstDataItem?.Insurance?.medical,
          lifeinsurance: firstDataItem?.Insurance?.lifeinsurance,
          vision: firstDataItem?.Insurance?.vision,
          Dental: firstDataItem?.Insurance?.Dental,
        },

        Promote_Distributions: firstDataItem.Promote_Distributions,
        Fund_allocations: firstDataItem.Fund_allocations,

        Other_Earnings: {
          field1: firstDataItem.Other_Earnings?.field1,
          fieldvalue1: firstDataItem.Other_Earnings?.fieldvalue1,
          field2: firstDataItem.Other_Earnings?.field2,
          fieldvalue2: firstDataItem.Other_Earnings?.fieldvalue2,
          field3: firstDataItem.Other_Earnings?.field3,
          fieldvalue3: firstDataItem.Other_Earnings?.fieldvalue3,
        },

        EPP_Promote_Distributions: firstDataItem.EPP_Promote_Distributions,
        EPP_Promote_Distributions2: {
          BVREP_IV_EPD2:
            firstDataItem.EPP_Promote_Distributions2?.BVREP_IV_EPD2,
          BVREP_V_EPD2: firstDataItem.EPP_Promote_Distributions2?.BVREP_V_EPD2,
          BVREP_VI_EPD2:
            firstDataItem.EPP_Promote_Distributions2?.BVREP_VI_EPD2,
          SHF_III_EPD2: firstDataItem.EPP_Promote_Distributions2?.SHF_III_EPD2,
          BVF_I_EPD2: firstDataItem.EPP_Promote_Distributions2?.BVF_I_EPD2,
          UCC_EPD2: firstDataItem.EPP_Promote_Distributions2?.UCC_EPD2,
          Cal_Regents_Boston_EPD2:
            firstDataItem.EPP_Promote_Distributions2?.Cal_Regents_Boston_EPD2,
          Cal_Regents_Syracuse_EPD2:
            firstDataItem.EPP_Promote_Distributions2?.Cal_Regents_Syracuse_EPD2,
          Cal_Regents_Seattle_EPD2:
            firstDataItem.EPP_Promote_Distributions2?.Cal_Regents_Seattle_EPD2,
          Harel_EPD2: firstDataItem.EPP_Promote_Distributions2?.Harel_EPD2,
          Gainesville_EPD2:
            firstDataItem.EPP_Promote_Distributions2?.Gainesville_EPD2,
          OP_Trust_EPD2:
            firstDataItem.EPP_Promote_Distributions2?.OP_Trust_EPD2,
          Samsung_Syracuse_EPD2:
            firstDataItem.EPP_Promote_Distributions2?.Samsung_Syracuse_EPD2,
          Samsung_Austin_EPD2:
            firstDataItem.EPP_Promote_Distributions2?.Samsung_Austin_EPD2,
          Stepstone_EPD2:
            firstDataItem.EPP_Promote_Distributions2?.Stepstone_EPD2,
          BVSHSSF_EPD2: firstDataItem.EPP_Promote_Distributions2?.BVSHSSF_EPD2,
        },

        EPP_Promote_Allocations: firstDataItem.EPP_Promote_Allocations,
      });
    }
  }, []);

  const checkAndUpdateData = (obj) => {
    const keysToCheck = [
      "Promote_Distributions",
      "Fund_allocations",
      "EPP_Promote_Distributions",
      "EPP_Promote_Allocations",
    ];

    keysToCheck.forEach((key) => {
      if (obj[key] && typeof obj[key] === "object") {
        Object.entries(obj[key]).forEach(([k, v]) => {
          if (v === undefined) {
            obj[key][k] = null; // Set to null if undefined
          }
        });
      }
    });
  };
  const onFinish = async (data) => {
    setShowDialog(false);
    const newObject = Object.fromEntries(
      Object.entries(data).map(([key, value]) => [
        key,
        value === undefined ? null : value,
      ])
    );
    // console.log(newObject);
    checkAndUpdateData(newObject);
    // console.log(newObject);
    newObject.Year = newObject.Year?.format("YYYY");
    // console.log(newObject.Year);
    // console.log(data.Year);
    const { info, ...newdata } = newObject;
    if (edit) {
      const id = empidforedit;

      const editobj = {
        ...newdata,
        firstname: getdatafedit.firstname,
        lastname: getdatafedit.lastname,
        email: getdatafedit.email,
        personid: getdatafedit.personid,
      };

      const newDataItem = { ...editobj, id };

      try {
        // console.log(data);
        const token = Cookies.get("token");
        // console.log(token);
        const config = {
          headers: {
            Authorization: token,
          },
        };
        const response = await axios.put(
          `${conf.baseurl}/edit/${id}`,
          newDataItem,
          config
        );
        // console.log("Server response:", response.data);
        if (response.data.status == "S") {
          toast.success("Data updated successfully.", {
            position: "top-center",
          });
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          // Redirect to login page
          toast.error("Session expired, Please login again");
          Cookies.remove("isLoggedIn");
          setIslogin(false);
          navigate("/");
        } else {
          // Handle other errors
          console.log("Error submitting form:", error);
          console.error("Error:", error.message);
        }
        // console.log('Error submitting form:', error);
      }
    } else {
      setLoading(true);
      const { info, ...newdata } = newObject;

      const { objVal } = info;

      const resultObject = {
        ...newdata,
        firstname: objVal.fname,
        lastname: objVal.lname,
        email: objVal.mail,
        personid: objVal.id,
      };
      // console.log(resultObject);
      const id = uuidv4();
      const newDataItem = { ...resultObject, id };

      try {
        // console.log(data);
        const token = Cookies.get("token");
        // console.log(token);
        const config = {
          headers: {
            Authorization: token,
          },
        };

        const response = await axios.post(
          `${conf.baseurl}/submit-form`,
          newDataItem,
          config
        );
        // console.log("Server response:", response);
        if (response.data.message == "F") {
          toast("Data for this employee and year already exists.", {
            position: "top-center",
          });
          window.scrollTo({ top: 0, behavior: "smooth" });
          setLoading(false);
          formRef.current.resetFields();
        } else if (response.data.message == "S") {
          toast.success("Form data submitted and saved.", {
            position: "top-center",
          });
          setLoading(false);
          formRef.current.resetFields();
          navigate("/showdata");
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          // Redirect to login page
          toast.error("Session expired, Please login again");
          Cookies.remove("isLoggedIn");
          setIslogin(false);
          navigate("/");
        } else {
          // Handle other errors
          console.log("Error submitting form:", error);
          console.error("Error:", error.message);
        }
      }
    }
  };

  function disabledFutureYear(current) {
    // Disable all future years
    // return current && dayjs(current).year() > dayjs().year();
    const currentYear = dayjs().year();
    const year = dayjs(current).year();
    return current && year > currentYear && year !== 2025;
  }

  const onFinishFailed = (errorInfo) => {
    // Scroll to the top of the page when form submission fails due to validation errors
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleYearChange = async (year) => {
    if (year) {
      const selectedYear = year.year();
      try {
        formRef.current.setFieldsValue(initialValues);
        const token = Cookies.get("token");
        const config = {
          headers: {
            Authorization: token,
          },
        };

        const response = await axios.get(
          `${conf.baseurl}/getdata/${getdatafedit.personid}`,
          config
        );
        // console.log(response.data);
        if (response.data) {
          const data = response.data.data.find(
            (item) =>
              item.personid === getdatafedit?.personid &&
              item.Year == selectedYear
          );
          // console.log(data);
          setEditdata(data);
          if (data) {
            setEmpidforedit(data.id);
            formRef.current.setFieldsValue({
              // Year: year ? dayjs(year) : "",
              // info: data.personid,
              Department: data.Department || null,
              Title: data.Title || null,
              Manager: data.Manager || null,
              Salary_Bonus: data.Salary_Bonus || {
                salary: null,
                lowbonusestimate: null,
                highbonusestimate: null,
                bonusestimate: null,
                bonusTarget: null,
                actualBonus: null,
                actualBonusReserve: null,
              },
              extrabonus: data.extrabonus,
              Match: data.Match,
              Footnote: data.Footnote,
              Insurance: data.Insurance || {
                medical: null,
                lifeinsurance: null,
                Dental: null,
                vision: null,
              },
              Promote_Distributions: data.Promote_Distributions || {},
              Fund_allocations: data.Fund_allocations || {},
              Other_Earnings: data.Other_Earnings || {
                field1: null,
                fieldvalue1: null,
                field2: null,
                fieldvalue2: null,
                field3: null,
                fieldvalue3: null,
              },
              EPP_Promote_Distributions: data.EPP_Promote_Distributions || {},
              EPP_Promote_Allocations: data.EPP_Promote_Allocations || {},
            });
          } else {
            toast.error("For this year data is not present");
            setEmpidforedit(null);
            const initialData = defineInitialData();
            setInitialValues(initialData);

            if (formRef.current) {
              formRef.current.setFieldsValue(initialData);
            }
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          toast.error("Session expired, Please login again");
          Cookies.remove("isLoggedIn");
          setIslogin(false);
          navigate("/");
        } else {
          console.error("Error fetching year data:", error.message);
        }
      }
    }
  };

  const handleValuesChange = () => {
    setShowDialog(true); // Set to true on any form change
  };

  return (
    <div className="container">
      <Modal
        open={showPrompt}
        onOk={confirmNavigation}
        onCancel={cancelNavigation}
      >
        <p>
          Are you sure you want to leave this page ? Your changes will be
          discarded.
        </p>
      </Modal>
      <Spin spinning={loading}>
        <Form
          layout="vertical"
          name="basic"
          ref={formRef}
          initialValues={initialValues}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          onValuesChange={handleValuesChange}
          // disabled={disableyears.includes(year)}
        >
          <div className="formWrapper">
            <Row>
              <Col span={24}>
                <div className="btns-row">
                  <Form.Item style={{ textAlign: "center" }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ paddingLeft: 20, paddingRight: 20 }}
                      // disabled={
                      //   edit
                      //     ? !empidforedit
                      //       ? true
                      //       : disableyears.includes(year)
                      //       ? true
                      //       : false
                      //     : false
                      // }
                      disabled={edit && !empidforedit}
                    >
                      {edit ? "Save" : "Submit"}
                    </Button>
                  </Form.Item>
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={7}>
                <Form.Item
                  label="Select Year"
                  name="Year"
                  mode="year"
                  rules={[{ required: true, message: "Please enter the year" }]}
                >
                  <DatePicker
                    picker="year"
                    format="YYYY"
                    onChange={(selectedyear) => {
                      if (edit) {
                        const year = dayjs(selectedyear).year();
                        handleYearChange(selectedyear);
                        setYear(year.toString());
                      }
                    }}
                    // disabled={edit}
                    // disabled={disableyears.includes(year)}
                    disabledDate={disabledFutureYear}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={1}></Col>
              <Col span={10}>
                <Form.Item
                  label="Select Employee"
                  name="info"
                  rules={[
                    { required: true, message: "Please select the info" },
                  ]}
                >
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    style={{ width: "300px" }}
                    disabled={edit}
                    onSelect={(v, obj) => {
                      formRef.current.setFieldsValue({ info: obj });
                    }}
                  >
                    {data.map((val) => {
                      return (
                        <Select.Option objVal={val} value={val.id} key={val.id}>
                          {val.fname + " " + val.lname + " " + val.mail}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={1}></Col>
              <Col span={7}>
                <Form.Item
                  label="Department"
                  // name="salary"
                  name="Department"
                  // rules={[{ required: true, message: 'Please enter the department' }]}
                >
                  <Input disabled={disableyears.includes(year)} />
                </Form.Item>
              </Col>
              <Col span={1}></Col>
              <Col span={7}>
                <Form.Item
                  label="Manager"
                  // name="salary"
                  name="Manager"
                  // rules={[{ required: true, message: 'Please enter the manager' }]}
                >
                  <Input disabled={disableyears.includes(year)} />
                </Form.Item>
              </Col>
              <Col span={1}></Col>
              <Col span={7}>
                <Form.Item
                  label="Title"
                  // name="salary"
                  name="Title"
                  // rules={[{ required: true, message: 'Please enter the title' }]}
                >
                  <Input disabled={disableyears.includes(year)} />
                </Form.Item>
              </Col>
            </Row>
            <hr></hr>
            <Collapse
              accordion={false}
              style={{ width: "100%" }}
              defaultActiveKey={["1", "2", "3", "4", "5", "6", "7", "8", "9"]}
            >
              <Panel header="Salary/Bonus" key="1" style={{ width: "100%" }}>
                <Row>
                  <Col span={24}>
                    <h4>Salary/Bonus</h4>
                  </Col>
                </Row>

                <Row>
                  <Col span={11}>
                    {/* <Form.Item name="Salary_Bonus"> */}
                    <Form.Item
                      label="Salary"
                      // name="salary"
                      name={["Salary_Bonus", "salary"]}
                      rules={[
                        {
                          pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                          message: "Please enter a valid number.",
                        },
                      ]}
                    >
                      <Input disabled={disableyears.includes(year)} />
                    </Form.Item>

                    <Form.Item
                      label="Low Bonus Estimate"
                      // name="lowbonusestimate"
                      name={["Salary_Bonus", "lowbonusestimate"]}
                      rules={[
                        {
                          pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                          message: "Please enter a valid number.",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item
                      label="High bonus Estimate"
                      // name="highbonusestimate"
                      name={["Salary_Bonus", "highbonusestimate"]}
                      rules={[
                        {
                          pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                          message: "Please enter a valid number.",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={11}>
                    <Form.Item
                      label="Bonus Estimate"
                      // name="bonusestimate"
                      name={["Salary_Bonus", "bonusestimate"]}
                      rules={[
                        {
                          pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                          message: "Please enter a valid number.",
                        },
                      ]}
                      hidden={true}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item
                      label="Bonus Target"
                      // name="bonusTarget"
                      name={["Salary_Bonus", "bonusTarget"]}
                      rules={[
                        {
                          pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                          message: "Please enter a valid number.",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item
                      label="Actual Bonus"
                      // name="actualBonus"
                      name={["Salary_Bonus", "actualBonus"]}
                      rules={[
                        {
                          pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                          message: "Please enter a valid number.",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item
                      label="Actual Bonus Reserve"
                      // name="actualBonusReserve"
                      name={["Salary_Bonus", "actualBonusReserve"]}
                      rules={[
                        {
                          pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                          message: "Please enter a valid number.",
                        },
                      ]}
                      hidden={true}
                    >
                      <Input />
                    </Form.Item>
                    {/* </Form.Item> */}
                  </Col>
                </Row>

                {/* <h4>Extra Bonuses</h4> */}
                <Row>
                  <Col span={11}>
                    <Form.Item
                      label="Extra Bonus"
                      name="extrabonus"
                      rules={[
                        {
                          pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                          message: "Please enter a valid number.",
                        },
                      ]}
                      // name={['ExtraBonuses', 'extrabonus']}
                    >
                      <Input disabled={disableyears.includes(year)} />
                    </Form.Item>
                  </Col>
                </Row>
              </Panel>
              <Panel header="401(K) Match" key="2" style={{ width: "100%" }}>
                <Row>
                  <Col span={11}>
                    <h4>401(K) Match</h4>
                  </Col>
                </Row>
                <Row>
                  <Col span={11}>
                    <Form.Item
                      label="401(K) Match"
                      name="Match"
                      rules={[
                        {
                          pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                          message: "Please enter a valid number.",
                        },
                      ]}
                      // name={['Match', 'kmatch401']}
                    >
                      <Input disabled={disableyears.includes(year)} />
                    </Form.Item>
                  </Col>
                </Row>
              </Panel>
              <Panel header="Insurance" key="3" style={{ width: "100%" }}>
                <Row>
                  <Col span={11}>
                    <h4>Insurance</h4>
                  </Col>
                </Row>
                {/* <Form.Item name="Insurance"> */}
                <Row>
                  <Col span={11}>
                    <Form.Item
                      label="Medical (BV pays 61% of Premium)"
                      // name="medical"
                      name={["Insurance", "medical"]}
                      rules={[
                        {
                          pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                          message: "Please enter a valid number.",
                        },
                      ]}
                    >
                      <Input disabled={disableyears.includes(year)} />
                    </Form.Item>

                    <Form.Item
                      label="LTD, STD, Life Insurance (100%)"
                      // name="lifeinsurance"
                      name={["Insurance", "lifeinsurance"]}
                      rules={[
                        {
                          pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                          message: "Please enter a valid number.",
                        },
                      ]}
                    >
                      <Input disabled={disableyears.includes(year)} />
                    </Form.Item>
                  </Col>
                  <Col span={11}>
                    <Form.Item
                      label="Dental (BV pays 50% of Premium)"
                      // name="Dental"
                      name={["Insurance", "Dental"]}
                      rules={[
                        {
                          pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                          message: "Please enter a valid number.",
                        },
                      ]}
                    >
                      <Input disabled={disableyears.includes(year)} />
                    </Form.Item>

                    <Form.Item
                      label="Vision (BV pays 50% of Premium)"
                      // name="vision"
                      name={["Insurance", "vision"]}
                      rules={[
                        {
                          pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                          message: "Please enter a valid number.",
                        },
                      ]}
                    >
                      <Input disabled={disableyears.includes(year)} />
                    </Form.Item>
                  </Col>
                </Row>
                {/* </Form.Item> */}
              </Panel>
              <Panel
                header="Promote Distributions"
                key="4"
                style={{ width: "100%" }}
              >
                <Row>
                  <Col span={11}>
                    <h4>Promote Distributions</h4>
                  </Col>
                </Row>

                <Row>
                  {promotedata.map((item, index) => (
                    <Col span={8} key={index}>
                      {/* Dynamic form field for Fund */}
                      <Form.Item
                        label={item.Fund} // Using Fund as label
                        name={["Promote_Distributions", `${item._id}`]} // Dynamic field name using Fund
                        rules={[
                          {
                            pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                            message: "Please enter a valid number.",
                          },
                        ]}
                      >
                        <Input
                          placeholder={`Enter ${item.Fund}`}
                          disabled={disableyears.includes(year)}
                        />
                      </Form.Item>
                    </Col>
                  ))}
                </Row>
                {/* </Form.Item> */}
              </Panel>
              <Panel
                header="Promote Allocations %"
                key="5"
                style={{ width: "100%" }}
              >
                <Row>
                  <Col span={11}>
                    <h4> Promote Allocations %</h4>
                  </Col>
                </Row>

                <Row>
                  {promotedata.map((item, index) => (
                    <Col span={8} key={index}>
                      <Form.Item
                        label={item.Fund} // Using Fund as label
                        name={["Fund_allocations", `${item._id}`]} // Dynamic field name using Fund
                        rules={[
                          {
                            pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                            message: "Please enter a valid number.",
                          },
                        ]}
                      >
                        <Input
                          placeholder={`Enter ${item.Fund}`}
                          disabled={disableyears.includes(year) || year == 2024}
                        />
                      </Form.Item>
                    </Col>
                  ))}
                </Row>
              </Panel>
              <Panel header="Other Earnings" key="6" style={{ width: "100%" }}>
                <Row>
                  <Col span={11}>
                    <h4>Other Earnings</h4>
                  </Col>
                </Row>
                {/* <Form.Item name="Other_Earnings"> */}
                <Row>
                  <Col span={11}>
                    <Form.Item
                      label="Field Title 1"
                      // name="gateway"
                      name={["Other_Earnings", "field1"]}
                    >
                      <Input disabled={disableyears.includes(year)} />
                    </Form.Item>
                    <Form.Item
                      label="Field value 1"
                      // name="gateway"
                      name={["Other_Earnings", "fieldvalue1"]}
                      rules={[
                        {
                          pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                          message: "Please enter a valid number.",
                        },
                      ]}
                    >
                      <Input disabled={disableyears.includes(year)} />
                    </Form.Item>
                    <Form.Item
                      label="Field Title 2"
                      // name="gateway"
                      name={["Other_Earnings", "field2"]}
                    >
                      <Input disabled={disableyears.includes(year)} />
                    </Form.Item>
                  </Col>
                  <Col span={11}>
                    <Form.Item
                      label="Field value 2"
                      // name="gateway"
                      name={["Other_Earnings", "fieldvalue2"]}
                      rules={[
                        {
                          pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                          message: "Please enter a valid number.",
                        },
                      ]}
                    >
                      <Input disabled={disableyears.includes(year)} />
                    </Form.Item>

                    <Form.Item
                      label="Field Title 3"
                      // name="gateway"
                      name={["Other_Earnings", "field3"]}
                    >
                      <Input disabled={disableyears.includes(year)} />
                    </Form.Item>

                    <Form.Item
                      label="Field value 3"
                      // name="gateway"
                      name={["Other_Earnings", "fieldvalue3"]}
                      rules={[
                        {
                          pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                          message: "Please enter a valid number.",
                        },
                      ]}
                    >
                      <Input disabled={disableyears.includes(year)} />
                    </Form.Item>
                  </Col>
                </Row>
                {/* </Form.Item> */}
              </Panel>
              {/* </Col> */}
              {/* <Col span={2}></Col> */}
              <Panel
                header="EPP Promote Distributions"
                key="7"
                style={{ width: "100%" }}
              >
                <Row>
                  <Col span={11}>
                    <h4>EPP Promote Distributions</h4>
                  </Col>
                </Row>

                <Row>
                  {promotedata.map((item, index) => (
                    <Col span={8} key={index}>
                      <Form.Item
                        label={item.Fund} // Using Fund as label
                        name={["EPP_Promote_Distributions", `${item._id}`]} // Dynamic field name using Fund
                        rules={[
                          {
                            pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                            message: "Please enter a valid number.",
                          },
                        ]}
                      >
                        <Input
                          placeholder={`Enter ${item.Fund}`}
                          disabled={disableyears.includes(year)}
                        />
                      </Form.Item>
                    </Col>
                  ))}
                </Row>
              </Panel>
              {/* <h4>EPP Promote Distributions 2</h4> */}
              <Form.Item name="EPP_Promote_Distributions2" hidden={true}>
                <Form.Item
                  label="BVREP IV %"
                  // name="BVREP_IV_EPD"
                  name={["EPP_Promote_Distributions2", "BVREP_IV_EPD2"]}
                  rules={[
                    {
                      pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                      message: "Please enter a valid number.",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="BVREP V"
                  // name="BVREP_V_EPD"
                  name={["EPP_Promote_Distributions2", "BVREP_V_EPD2"]}
                  rules={[
                    {
                      pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                      message: "Please enter a valid number.",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="BVREP VI"
                  // name="BVREP_VI_EPD"
                  name={["EPP_Promote_Distributions2", "BVREP_VI_EPD2"]}
                  rules={[
                    {
                      pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                      message: "Please enter a valid number.",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="SHF III"
                  // name="SHF_III_EPD"
                  name={["EPP_Promote_Distributions2", "SHF_III_EPD2"]}
                  rules={[
                    {
                      pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                      message: "Please enter a valid number.",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="BVF I"
                  // name="BVF_I_EPD"
                  name={["EPP_Promote_Distributions2", "BVF_I_EPD2"]}
                  rules={[
                    {
                      pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                      message: "Please enter a valid number.",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="UCC"
                  // name="UCC_EPD"
                  name={["EPP_Promote_Distributions2", "UCC_EPD2"]}
                  rules={[
                    {
                      pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                      message: "Please enter a valid number.",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Cal Regents Boston"
                  // name="Cal_Regents_Boston_EPD"
                  name={[
                    "EPP_Promote_Distributions2",
                    "Cal_Regents_Boston_EPD2",
                  ]}
                  rules={[
                    {
                      pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                      message: "Please enter a valid number.",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Cal Regents Syracuse"
                  // name="Cal_Regents_Syracuse_EPD"
                  name={[
                    "EPP_Promote_Distributions2",
                    "Cal_Regents_Syracuse_EPD2",
                  ]}
                  rules={[
                    {
                      pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                      message: "Please enter a valid number.",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Cal Regents  Seattle"
                  // name="Cal_Regents_Seattle_EPD"
                  name={[
                    "EPP_Promote_Distributions2",
                    "Cal_Regents_Seattle_EPD2",
                  ]}
                  rules={[
                    {
                      pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                      message: "Please enter a valid number.",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Harel"
                  // name="Harel_EPD"
                  name={["EPP_Promote_Distributions2", "Harel_EPD2"]}
                  rules={[
                    {
                      pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                      message: "Please enter a valid number.",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Gainesville"
                  // name="Gainesville_EPD"
                  name={["EPP_Promote_Distributions2", "Gainesville_EPD2"]}
                  rules={[
                    {
                      pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                      message: "Please enter a valid number.",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="OP Trust"
                  // name="OP_Trust_EPD"
                  name={["EPP_Promote_Distributions2", "OP_Trust_EPD2"]}
                  rules={[
                    {
                      pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                      message: "Please enter a valid number.",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Samsung  Syracuse"
                  // name="Samsung_Syracuse_EPD"
                  name={["EPP_Promote_Distributions2", "Samsung_Syracuse_EPD2"]}
                  rules={[
                    {
                      pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                      message: "Please enter a valid number.",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Samsung  Austin"
                  // name="Samsung_Austin_EPD"
                  name={["EPP_Promote_Distributions2", "Samsung_Austin_EPD2"]}
                  rules={[
                    {
                      pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                      message: "Please enter a valid number.",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Stepstone"
                  // name="Stepstone_EPD"
                  name={["EPP_Promote_Distributions2", "Stepstone_EPD2"]}
                  rules={[
                    {
                      pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                      message: "Please enter a valid number.",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="BVSHSSF %"
                  // name="Stepstone_EPD"
                  name={["EPP_Promote_Distributions2", "BVSHSSF_EPD2"]}
                  rules={[
                    {
                      pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                      message: "Please enter a valid number.",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Form.Item>
              <Panel
                header="EPP Promote Share %"
                key="8"
                style={{ width: "100%" }}
              >
                <Row>
                  <Col span={11}>
                    <h4>EPP Promote Share %</h4>
                  </Col>
                </Row>

                <Row>
                  {promotedata.map((item, index) => (
                    <Col span={8} key={index}>
                      <Form.Item
                        label={item.Fund} // Using Fund as label
                        name={["EPP_Promote_Allocations", `${item._id}`]} // Dynamic field name using Fund
                        rules={[
                          {
                            pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                            message: "Please enter a valid number.",
                          },
                        ]}
                      >
                        <Input
                          placeholder={`Enter ${item.Fund}`}
                          disabled={disableyears.includes(year) || year == 2024}
                        />
                      </Form.Item>
                    </Col>
                  ))}
                </Row>
              </Panel>
              <Panel header="Footnote" key="9" style={{ width: "100%" }}>
                <h4>Footnote</h4>
                <Form.Item label="Footnote" name="Footnote">
                  <Input disabled={disableyears.includes(year)} />
                </Form.Item>
              </Panel>
            </Collapse>
            {/* </Row> */}
            <div className="btns-row" style={{ marginTop: 20 }}>
              <Form.Item style={{ textAlign: "center" }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ paddingLeft: 20, paddingRight: 20 }}
                  // disabled={edit ? (!empidforedit ? true : false) : false}
                  disabled={edit && !empidforedit}
                >
                  {edit ? "Save" : "Submit"}
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </Spin>
    </div>
  );
};
export default Formpage;
